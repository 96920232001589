//Navbar.js
import React,{useEffect} from 'react';

import {Logo, NavbarHeader, SearchDiv} from "./navbar.styles";
import './navbar.scss'
import {Button} from "../../../dashboard.styles";
import web3Client from "../../Web3Client";
import BannerImg from "../../../images/banner.png";

const Navbar = (props) => {

    const [scrolled,setScrolled] = React.useState(false);

    const [address, setAddress] = React.useState("");

    const [icon, setIcon] = React.useState("");

    const web3 = web3Client.getWeb3();

    const handleScroll = () => {
        const offset = window.scrollY;
        if(offset > 0 ){
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })
    let navbarClasses = [];
    if(scrolled){
        navbarClasses.push('scrolled');
    }


    const handleSearch = () => {
        // console.log(address)
        props.setNewAddress(address)
    }

    const keyPress = (event) => {
        // console.log(event)
        if(event.code === "Enter"){
            addressInput(event.target.value)
            handleSearch(address)
        }
    }

    const addressInput = (address) => {
        setAddress(address)

        if(address.endsWith(".eth", address.length)){
            web3.eth.ens.getAddress(address).then((ensAddress, error) => {

                if(web3.utils.isAddress(ensAddress)){
                    setIcon("fas fa-check-circle correct")
                    setAddress(ensAddress)
                }else{
                    setIcon("fas fa-times-circle error")
                }
            }).catch((error) => {
                console.log("ERROR", error)
                setIcon("fas fa-times-circle error")
            })
        }else{
            setIcon("")

            if(address.length === 42){
                if(web3.utils.isAddress(address)){
                    setIcon("fas fa-check-circle correct")
                    setAddress(address)
                }else{
                    setIcon("fas fa-times-circle error")
                }
            }

        }

    }
    return (
        <NavbarHeader theme={props.theme} className={navbarClasses.join(" ")}>
            <Logo className="logo">
                <img src={BannerImg}></img>
            </Logo>
            {
                (window.ethereum !== undefined && window.ethereum.selectedAddress === null)
                    ?
                    <div>
                        <Button theme={props.theme} style={{backgroundColor: props.theme.myNode.main, lineHeight: 'unset', height:"30px"}} onClick={() => props.connectWallet()}>Connect</Button>
                    </div>
                    :
                    <></>
            }
            <SearchDiv className="search">
                <button onClick={() => handleSearch(address)}>Search</button>
                <input placeholder={"Search for Address or ENS-Domain"} onKeyPress={(event) => keyPress(event)} onChange={(event) => {addressInput(event.target.value)}}/>
                <span>
                    <i className={icon}></i>
                </span>
            </SearchDiv>

            {/*<button onClick={() => props.disconnectWallet()}>Disconnect</button>*/}

            <nav className="navigation">
                {props.children}
            </nav>
        </NavbarHeader>
    )
};



export default Navbar;