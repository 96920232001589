import React from 'react'
import styled from 'styled-components'
import { FaCommentAlt, FaThumbsUp, FaRegEye } from 'react-icons/fa'
import Card from './Card'

const StyledRoot = styled.div`
  padding: 20px 12px;
`

const StyledContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin: auto;
`

const Parent = (props) => {

    const date = new Date().toDateString()+" "+new Date().toLocaleTimeString()

    const onCommentClick = () => alert('You clicked comments')
    const onLikesClick = () => alert('You clicked comments')
    const onViewsClick = () => alert('You clicked comments')

    const buttons = [
        {
            label: (
                <>
                    <FaCommentAlt /> 0 Comments
                </>
            ),
            onClick: onCommentClick,
        },
        {
            label: (
                <>
                    <FaThumbsUp /> 242 Likes
                </>
            ),
            onClick: onLikesClick,
        },
        {
            label: (
                <>
                    <FaRegEye /> 187288 Views
                </>
            ),
            onClick: onViewsClick,
        },
    ]

    return (
        <StyledRoot>
            <StyledContainer>
                {/*<Card*/}
                {/*    title="The Benefits of Green Apples"*/}
                {/*    date={date}*/}
                {/*    description=""*/}
                {/*    actions={buttons}*/}
                {/*    theme={props.theme}*/}
                {/*/>*/}

                <Card
                    nodeAddress={props.nodeAddress}
                    isMyNode={props.isMyNode}
                    title={props.title}
                    date={date}
                    nodesCount={props.nodesCount}
                    nodesCountPolygon={props.nodesCountPolygon}

                    strongPriceToken={props.priceToken}
                    strongerPriceToken={props.strongerPriceToken}

                    strongTokenBalance={props.strongTokenBalance}
                    strongerTokenBalance={props.strongerTokenBalance}

                    pendingReward={props.pendingReward}
                    // pendingRewardPolygon={props.pendingRewardPolygon}

                    strongPriceInCurrency={props.strongPriceInCurrency}
                    strongerPriceInCurrency={props.strongerPriceInCurrency}

                    poolStakedAmount={props.poolStakedAmount}
                    poolPendingReward={props.poolPendingReward}

                    description={props.description}
                    fiatCurrency={props.fiatCurrency}
                    actions={buttons}
                    theme={props.theme}

                    floatingNumber={props.floatingNumber}

                    deleteNode={props.deleteNode}
                >

                </Card>
            </StyledContainer>
        </StyledRoot>
    )
}

export default Parent