import {StrongABI, TokenABI} from "./TokenABI";

export class Settings{

    constructor() {

        this.tokenABI = new TokenABI();
        this.strongABI = new StrongABI();

        this.chainData = {
            ethereum: [
                {
                    chainId: "0x1",
                    chainName: "ETH",
                    nativeCurrency:
                        {
                            name: "ETH",
                            symbol: "ETH",
                            decimal: 18
                        },
                    // rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161', 'wss://mainnet.infura.io/ws/v3/7e0a0d93bf0b46229adc706ab552e65e'],
                    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161', 'wss://eth-mainnet.alchemyapi.io/v2/FWPOomHhtwtPbU0IxJV0SwKTOJTLFZn1'],
                    // rpcUrls: ['https://speedy-nodes-nyc.moralis.io/1583c2c58cc1bb9991ec8f6f/eth/mainnet'],
                    // rpcUrls: ['wss://speedy-nodes-nyc.moralis.io/1583c2c58cc1bb9991ec8f6f/eth/mainnet/ws'],
                    blockExplorerUrls: ['https://etherscan.io']
                }
            ],
            polygon: [
                // {
                //     chainId: "0x89",
                //     chainName: 'MATIC',
                //     nativeCurrency:
                //         {
                //             name: 'MATIC',
                //             symbol: 'MATIC',
                //             decimals: 18
                //         },
                //     rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
                //     blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/']
                // },
                {
                    chainId: "0x137",
                    chainName: 'Polygon Chain',
                    nativeCurrency:
                        {
                            name: 'MATIC',
                            symbol: 'MATIC',
                            decimals: 18
                        },
                    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
                    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/']
                }
            ],
            binance: [
                {
                    chainId: "0x56",
                    chainName: 'BSC',
                    nativeCurrency:
                        {
                            name: 'BNB',
                            symbol: 'BNB',
                            decimals: 18
                        },
                    rpcUrls: ['https://bsc-dataseed.binance.org/', 'wss://speedy-nodes-nyc.moralis.io/1583c2c58cc1bb9991ec8f6f/bsc/mainnet/ws'],
                    blockExplorerUrls: ['https://bscscan.com']
                }
            ],
            fantom: [
                {
                    chainId: "0xfa",
                    chainName: 'FTM',
                    nativeCurrency:
                        {
                            name: 'FTM',
                            symbol: 'FTM',
                            decimals: 18
                        },
                    rpcUrls: ['https://rpcapi.fantom.network'],
                    blockExplorerUrls: ['https://ftmscan.com']
                },
            ],
            avax: [
                {
                    chainId: "0xa86a",
                    chainName: 'AVAX',
                    nativeCurrency:
                        {
                            name: 'AVAX',
                            symbol: 'AVAX',
                            decimals: 18
                        },
                    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                    blockExplorerUrls: ['https://cchain.explorer.avax.network/']
                },
            ],

        }

        this.tokenData = {
            ethereum: {
                token: {
                    USDT: {
                        symbol: "USDT",
                        name: "Tether USD",
                        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
                        decimal: 6
                    },
                    USDC: {
                        symbol: "USDC",
                        name: "USD Coin",
                        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
                        decimal: 6
                    },
                    DAI: {
                        symbol: "DAI",
                        name: "Dai Token",
                        address: '0x6b175474e89094c44da98b954eedeac495271d0f',
                        decimal: 18
                    },
                    ETH: {
                        symbol: "ETH",
                        name: "Wrapped Ethereum Token",
                        address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
                        decimal: 18
                    },
                    BTC: {
                        symbol: "BTC",
                        name: "Wrapped BTC",
                        address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
                        decimal: 8
                    },
                    STRONG: {
                        symbol: "STRONG",
                        name: "Strong Token",
                        address: "0x990f341946A3fdB507aE7e52d17851B87168017c",
                        decimal: 18
                    }
                }
            },
            polygon: {
                token: {
                    USDT: {
                        symbol: "USDT",
                        name: "Tether USD",
                        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
                        decimal: 6
                    },
                    USDC: {
                        symbol: "USDC",
                        name: "USD Coin",
                        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                        decimal: 6
                    },
                    DAI: {
                        symbol: "DAI",
                        name: "Dai Token",
                        address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
                        decimal: 18
                    },
                    ETH: {
                        symbol: "ETH",
                        name: "Wrapped Ethereum Token",
                        address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
                        decimal: 18
                    },
                    BTC: {
                        symbol: "BTC",
                        name: "(PoS) Wrapped BTC",
                        address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
                        decimal: 18
                    },
                }
            },
            binance: {
                token: {
                    USDT: {
                        symbol: "USDT",
                        name: "Tether USD",
                        address: '0x55d398326f99059ff775485246999027b3197955',
                        decimal: 18
                    },
                    USDC: {
                        symbol: "USDC",
                        name: "USD Coin",
                        address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
                        decimal: 18
                    },
                    DAI: {
                        symbol: "DAI",
                        name: "Dai Token",
                        address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
                        decimal: 18
                    },
                    WBNB: {
                        symbol: "WBNB",
                        name: "Wrapped BNB",
                        address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
                        decimal: 18
                    },
                    ETH: {
                        symbol: "ETH",
                        name: "Binance-Peg Ethereum Token",
                        address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
                        decimal: 18
                    },
                    BTC: {
                        symbol: "BTC",
                        name: "Binance-Peg BTCB Token",
                        address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
                        decimal: 18
                    },
                    BUSD: {
                        symbol: "BUSD",
                        name: "Binance-Peg BUSD Token",
                        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                        decimal: 18
                    },
                    CAKE: {
                        symbol: "CAKE",
                        name: "PancakeSwap Token",
                        address: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
                        decimal: 18
                    },

                }
            },
            fantom: {
                token: {
                    USDT: {
                        symbol: "USDT",
                        name: "Frapped Tether USD",
                        address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
                        decimal: 6
                    },
                    USDC: {
                        symbol: "USDC",
                        name: "USD Coin",
                        address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
                        decimal: 6
                    },
                    DAI: {
                        symbol: "DAI",
                        name: "Dai Token",
                        address: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
                        decimal: 18
                    }
                }
            },
            avax: {
                token: {
                    USDT: {
                        symbol: "USDT",
                        name: "Tether USD",
                        address: '0xc7198437980c041c805a1edcba50c1ce5db95118',
                        decimal: 6
                    },
                }
            }
        }

        this.explorerData = {
            polygon: {
                tx: "https://explorer-mainnet.maticvigil.com/tx/"
            },
            binance: {
                tx: "https://bscscan.com/tx/"
            },
            fantom: {
                tx: "https://ftmscan.com/tx/"
            }
        }

    }

    getTokenDataFromNetwork = (chainName) => {
        return this.tokenData[chainName].token;
    }

    getAllSupportedNetworks = () => {
        return this.chainData
    }

    getChainDataFromChainId = (chainId) => {
        // console.log("ChainDataFromChainId: " + chainId);
        for(let networkName in this.chainData){
            let networkDataSet = this.chainData[networkName];

            for(let networkData of networkDataSet){
                // console.log(networkData);
                if(chainId === networkData.chainId){
                    // console.log("FOUND", {networkData, networkName})
                    return {networkData, networkName};
                }
            }

        }
        return null;
    }

    getChainDataFromChainName(chainName){
        for(let networkName in this.chainData){
            if(networkName === chainName){
                let networkData = this.chainData[networkName][0];
                return {networkData, networkName}
            }
            // let networkData = this.chainData[networkName][0];
            // if(chainId === networkData.chainId){
            //     console.log("FOUND", {networkData, networkName})
            //     return {networkData, networkName};
            // }
        }
    }

    getTokenData(chainName, tokenName){
        for(let networkName in this.tokenData){
            if(networkName === chainName){
                let networkToken = this.tokenData[networkName].token;
                for(let token in networkToken){
                    if(token === tokenName)
                        return(networkToken[token])
                }
            }
            // console.log(networkToken)
        }
    }

    getTokenList(chainName){
        return this.tokenData[chainName].token;
    }

    getExplorerURL(chainName){
        return this.explorerData[chainName].tx
    }

    getExplorerURLofTX(chainName, tx_hash){
        return this.explorerData[chainName].tx+tx_hash;
    }

}