
export class EventComponent{

    Topics = {
        web3client: {
           NEW_PRICE: "new_price"
        }
    }
    subscribers = {}

    /**
     * Pub-Sub-Pattern
     * @param eventName
     * @param data
     */
    publish = (eventName, data) => {
        // console.log(this.subscribers, eventName)
        if (!Array.isArray(this.subscribers[eventName])) {
            return
        }
        this.subscribers[eventName].forEach((callback) => {
            // console.log("Call subscribers callback")

            callback(data)
        })
    }
    /**
     * Subscribe to a specific Topic to receive Updates
     * @param eventName - Topics
     * @param callback
     */
    subscribe = (eventName, callback) => {
        // console.log("Sub has been called", eventName, callback)
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = []
        }

        this.subscribers[eventName].push(callback)
    }
    unsubscribe = (eventName, callback) => {
        if (this.subscribers[eventName]) {
            for (let i = 0; i < this.subscribers[eventName].length; i++) {
                if (this.subscribers[eventName][i] === callback) {
                    this.subscribers[eventName].splice(i, 1);
                    // console.log("Unsubscribed from subscription")
                    break;
                }
            }
        }
    }
}