import styled from 'styled-components';

export const NavbarHeader = styled.header`
  z-index: 100;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.primary.main};
  transition: all .3s ease-in;

  padding: 0px 20px;

  @media (max-width: 500px) {
    justify-content: left;
  }

`;

export const SearchDiv = styled.div`

  position: relative;

  width: 100%;
  text-align: center;
  //left: 40px;

  input{
    position: relative;
    //left: 40px;
    width: 60%;
    //max-width: 75%;
    height: 30px;
    padding-left: 16px;
    padding-right: 24px;


    @media (max-width: 500px) {
      width: 50%;
      left: 0px;
    }

  }

  button{
    width: 80px;
    height: 36px;

    @media (max-width: 500px) {
      width: 60px;
      font-size: 0.75rem;
    }

    //border: unset;
  }

  span{
    position: relative;
    left: -24px;

    i.error{
      color: red;
    }
    i.correct{
      color: green;
    }
  }

`;

export const Select = styled.select`

  //font-family: 'Font Awesome 5 Free', 'sans-serif';
  //font-weight: 900;

  width: 100px;
  text-align: center;
  height: 30px;

  border-radius: 5px;

  @media (max-width: 500px) {
    width: 60px;
    font-size: 0.75rem;
  }
`;

export const Logo = styled.div`
  
  img{
    width: 40px;
    filter: brightness(0) invert(1);
  }
`;