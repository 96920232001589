import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`

  position: relative;
  
  border: ${(props) => ` ${props.isMyNode ? '2px' : '1px'} solid ${props.isMyNode ? props.theme.border.main : props.theme.border.cool}`};
  border-radius: ${(props) => `${props.isMyNode ? '0px' : '10px'}`};
  padding: 2px 12px 18px;
  // background: ${(props) => `linear-gradient(
  //   45deg, ${props.theme.primary.main}, ${props.theme.secondary.main}
  // )`};
  background: ${(props) => `linear-gradient(
    45deg, ${props.theme.primary.main}, ${props.cardBackground}
  )`};
`

const Title = styled.h2`
  color: #fff;
  font-weight: 300;
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`

const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 6px 0;
  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`

const Description = styled.p`
  margin: 2px 0px;
  color: #fff;
  //background: #eceff1;
  font-weight: 300;
  @media (max-width: 500px) {
    font-size: 0.75rem;
  }
`
const Heading = styled.div`
  
  position: relative;
  color: #fff;
  //background: #eceff1;
  font-weight: 400;
  font-size: 1.05rem;
  
  border-bottom: 1px solid #eceff1;

  margin-bottom: 6px;
  
  span{
    justify-content: right;
  }
  
  @media (max-width: 500px) {
    font-size: 0.75rem;
  }
`

const Balance = styled.p`
  margin: 0px;
  color: #fff;
  font-weight: 300;
  
  //justify-self: right;
  
  @media (max-width: 500px) {
    font-size: 0.70rem;
  }
`

const PriceToken = styled.p`
  color: #fff;
  font-weight: 300;
  @media (max-width: 500px) {
    font-size: 0.75rem;
  }
`

const PendingReward = styled.p`
  color: #fff;
  font-weight: 300;
  @media (max-width: 500px) {
    font-size: 0.75rem;
  }
`

const PriceInCurrency = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 500px) {
    font-size: 0.75rem;
  }
`

const Actions = styled.div`
  color: #333;
  display: flex;
  align-items: center;
  svg {
    transform: translateY(2px);
    margin-right: 5px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    & button {
      width: 100%;
      margin-bottom: 4px;
      font-size: 0.65rem;
    }
  }
`

const Action = styled.button`
  margin: 0 5px;
  padding: 8px 14px;
  background: rgba(155, 155, 155, 0.2);
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  outline: 0;
  font-weight: 300;
  :hover {
    opacity: 0.8;
  }
  :active {
    background: ${(props) => props.theme.primary.main};
  }
`

const PoolDiv = styled.div`
  
    padding: 6px 6px 12px 6px;
    margin: 12px 0px;
    
    background: #31a4e3;
    border: 1px solid #eceff1;

  div{
      display: grid;
      grid-template-columns: repeat(2, 50%);
      //flex-direction: row;
      justify-items: left;
    
      //gap: 10px;
        
  }
  
`;

const TotalDiv = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 50%);
  
  p{
    margin: 0px;
  }
  
`;

const CloseButton = styled.button`
  position: relative;
  float: right;
  
  //border: 1px solid black;
  border: unset;
  border-top-right-radius: 10px;
  
  height: 24px;
  width: 24px;
  
  cursor: pointer;
  
  transform: translate(12px, -2px);
  
  :hover{
    background-color: red;
    color: white;
  }
  
`;

const Card = ({
        nodeAddress,
        isMyNode,
        title,
        nodesCount,
        nodesCountPolygon,
        date,

        strongPriceToken,
        strongerPriceToken,

        pendingReward,
        // pendingRewardPolygon,
        strongPriceInCurrency,
        strongerPriceInCurrency,

        poolStakedAmount,
        poolPendingReward,

        strongTokenBalance,
        strongerTokenBalance,

        fiatCurrency,

        floatingNumber,

        deleteNode,

        theme
    }) => (
    <StyledContainer
        theme={theme}
        isMyNode={isMyNode}
        cardBackground={ (isMyNode) ? theme.myNode.main : theme.secondary.main }
    >
        {(!isMyNode) ? <CloseButton onClick={() => {deleteNode(nodeAddress)} }>X</CloseButton> : <></>}
        <Title>STRONG | STRONGER<span style={{float: 'right'}}>Nodes: {Number(nodesCount)+Number(nodesCountPolygon)}</span> </Title>
        <Title>{title}</Title>
        <Date>{date}</Date>



        <TotalDiv>
            {/*<PriceToken>(Strong)  Price: {strongPriceToken + " "+fiatCurrency.icon}</PriceToken>*/}
            {/*<Balance style={{justifySelf: 'right'}}>Balance: {strongTokenBalance}</Balance>*/}

            {/*<PriceToken>(Stronger)Price: {strongerPriceToken + " "+fiatCurrency.icon}</PriceToken>*/}
            {/*<Balance style={{justifySelf: 'right'}}>Balance: {strongerTokenBalance}</Balance>*/}
            <PoolDiv style={{backgroundColor: 'grey'}}>
                <Heading>
                    <p>STRONG</p>
                    <span>{strongPriceToken + " "+fiatCurrency.icon}</span>
                </Heading>
                <div style={{justifyItems: 'left'}}>
                    <Description>Balance</Description>
                    <Balance>{strongTokenBalance}</Balance>

                    <Description>Total</Description>
                    <Balance>{strongPriceInCurrency + " " + fiatCurrency.icon}</Balance>
                </div>
            </PoolDiv>
            <PoolDiv>
                <Heading>
                    <p>STRONGER</p>
                    <span>{strongerPriceToken + " "+fiatCurrency.icon}</span>
                </Heading>
                <div style={{justifyItems: 'left'}}>

                    <Description>Balance</Description>
                    <Balance>{ strongerTokenBalance } </Balance>

                    <Description>Total</Description>
                    <Balance>{ Number(strongerTokenBalance * strongerPriceToken).toFixed(floatingNumber) + " " + fiatCurrency.icon}</Balance>
                </div>
            </PoolDiv>

        </TotalDiv>

        {/*<Description>{description}</Description>*/}
        <PoolDiv>
            <Heading>Nodes Infos</Heading>
            <div >
                <Description>Ethereum: {nodesCount}</Description>
                {/*<Description>Claimable</Description>*/}
                {/*<Balance>{nodesCount} (+{nodesCountPolygon})</Balance>*/}
                <Description>Polygon: {nodesCountPolygon}</Description>
                <Description>Total Claimable</Description>
                <Balance>{pendingReward} ( {(pendingReward*strongerPriceToken).toFixed(floatingNumber) + " "+fiatCurrency.icon} )</Balance>
            </div>
        </PoolDiv>

        <PoolDiv>

            <Heading>Pool Infos</Heading>
            <div >
                {/*<Description>Staked Token</Description>*/}
                {/*<Description>Claimable</Description>*/}
                <Balance>Staked: {poolStakedAmount} ( {(poolStakedAmount*strongerPriceToken).toFixed(floatingNumber) + " "+fiatCurrency.icon} )</Balance>
                <Balance>{poolPendingReward} ( {(poolPendingReward*strongerPriceToken).toFixed(floatingNumber) + " "+fiatCurrency.icon} )</Balance>
            </div>
        </PoolDiv>

        {/*<Balance>{tokenBalance}</Balance>*/}

        {/*<PendingReward>{pendingReward}</PendingReward>*/}

        <TotalDiv>
            <Description>Claimable ( {Number(pendingReward+poolPendingReward).toFixed(floatingNumber) } )</Description>
            <Description style={{justifySelf: 'right'}}>{ ((pendingReward+poolPendingReward)*strongerPriceToken).toFixed(floatingNumber) + " "+fiatCurrency.icon}</Description>

            <PriceInCurrency>
                Total Strong ( { Number(strongTokenBalance).toFixed(floatingNumber) } )
            </PriceInCurrency>
            <PriceInCurrency style={{justifySelf: 'right'}} >{strongPriceInCurrency + " " + fiatCurrency.icon}</PriceInCurrency>

            <PriceInCurrency>
                Total Stronger ( { Number(strongerTokenBalance + pendingReward + poolStakedAmount + poolPendingReward).toFixed(floatingNumber) } )
            </PriceInCurrency>
            <PriceInCurrency style={{justifySelf: 'right'}} >{strongerPriceInCurrency + " " + fiatCurrency.icon}</PriceInCurrency>

        </TotalDiv>

        {/*<Actions>*/}
        {/*    {actions.map(({ label }) => (*/}
        {/*        <Action theme={theme}>{label}</Action>*/}
        {/*    ))}*/}
        {/*</Actions>*/}
    </StyledContainer>
)

export default Card;