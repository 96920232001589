import {EventComponent} from "./EventComponent";
import Web3 from "web3";
import {Settings} from "./Settings";

class Web3Client extends EventComponent {

    // static web3Client = null;
    //
    // static getInstance() {
    //     if (!Web3Client.web3Client) {
    //         Web3Client.web3Client = new Web3Client();
    //     }
    //     return Web3Client.web3Client;
    //
    //     // if(!Web3Client._instance)
    //
    // }

    utils = new Web3ClientUtils();

    apikey = "";

    constructor() {
        super();

        if(Web3Client._instance){
            Web3Client._instance = this;
        }

        this.state = {
            chainClients: {
                ethereum: this.utils.getWeb3WsOfChain("ethereum")
            },
            extension: {
                web3: null
            }
        }

        // this.#checkExtension().then(web3 => {
        //     console.log(web3)
        //     this.state.extension.web3 = web3;
        // }).catch(reason => {
        //     alert(reason)
        // })

        return Web3Client._instance;
    }

    getWeb3 = () => {
        return this.state.chainClients.ethereum;
    }

    enable = async() => {
        return this.#checkExtension();
    }

    // disable = async() => {
    //     if(window.ethereum){
    //         console.log("Disconnect")
    //         console.log(window.ethereum)
    //     }
    // }

    #checkExtension = async() => {

        // if(window.ethereum){
        //     await window.ethereum.enable();
        // }else{
        //
        // }

        if(window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts'})
            // await window.ethereum.send('eth_requestAccounts');

            window.web3 = new Web3(window.ethereum);

            this.state.extension.web3 = window.ethereum;

            return window.ethereum;
        }else{
            alert("No Extension available")
            throw new Error("No Extension available")
        }

    }

    getAccounts = async() => {
        console.log(this.state.extension)
        return await this.state.extension.web3.request({method: 'eth_accounts'});
    }

    getBlockNumber = async() => {
        return await this.state.chainClients.ethereum.eth.getBlockNumber();
    }

    getStrongServiceContract_Polygon = () => {

        let contractObject = {
            address: "0xC5622f143972A5Da6aaBC5F5379311eE5EB48568",
            abi: this.utils.settings.strongABI.getStrongServicePolygonABI()
        }

        let contract = new this.state.chainClients.ethereum.eth.Contract(contractObject.abi, contractObject.address);
        return contract;
    }

    getStrongServiceContract = () => {

        let contractObject = {
            address: "0xFbdDaDD80fe7bda00B901FbAf73803F2238Ae655",
            abi: this.utils.settings.strongABI.getStrongServiceABI()
        }



        let contract = new this.state.chainClients.ethereum.eth.Contract(contractObject.abi, contractObject.address);

        return contract;
    }

    getStrongPoolServiceContract = () => {

        let contractObject = {
            address: "0x53cA51Ba980B6475C13d158c1825013cf81038Fc",
            abi: this.utils.settings.strongABI.getStrongPoolServiceABI()
        }

        let contract = new this.state.chainClients.ethereum.eth.Contract(contractObject.abi, contractObject.address);

        return contract;
    }

    getStrongTokenContract = () => {
        let contractObject = {
            address: "0x990f341946A3fdB507aE7e52d17851B87168017c",
            abi: this.utils.settings.tokenABI.getERC20ABI()
        }
        let contract = new this.state.chainClients.ethereum.eth.Contract(contractObject.abi, contractObject.address);
        return contract
    }

    getStrongerTokenContract = () => {
        let contractObject = {
            address: "0xDc0327D50E6C73db2F8117760592C8BBf1CDCF38",
            abi: this.utils.settings.tokenABI.getERC20ABI()
        }
        let contract = new this.state.chainClients.ethereum.eth.Contract(contractObject.abi, contractObject.address);
        return contract
    }

    setApiKey(apiKey) { this.apikey = apiKey; }

    fetchContract = async(address) => {

        this.router = {}

        let request;
        let abi;

        let dex_1inch = {}

        // console.log("Fetching Contract", this.apikey)

        request = await fetch("https://api.etherscan.com/api?module=contract&action=getabi&address="+address+"&apikey="+this.apikey)
        // request = await fetch("https://api.etherscan.com/api?module=contract&action=getabi&address=0x4161D3e2024D9773C18EF63E2d93d1D752C0486b&apikey="+this.apikey)
        abi = await request.json();

        return abi;
    }
}

class Web3ClientUtils{

    settings = new Settings();
    utils = new Web3().utils;

    getWeb3OfChain = (chainName) => {
        if(chainName === "binance")
            return this.getWeb3Ws(this.settings.getChainDataFromChainName(chainName).networkData.rpcUrls[1]);
        else
            return this.getWeb3Http(this.settings.getChainDataFromChainName(chainName).networkData.rpcUrls[0]);
    }
    getWeb3WsOfChain = (chainName) => {
        return this.getWeb3Ws(this.settings.getChainDataFromChainName(chainName).networkData.rpcUrls[1]);
    }

    getWeb3OfExtension = (ethereum) => {
        return new Web3(ethereum)
    }

    getWeb3Http = (chainRPC_URL) => {
        let web3 = new Web3()
        web3.setProvider(new Web3.providers.HttpProvider(chainRPC_URL))
        return web3;
    }

    getWeb3Ws = (chainRPC_URL) => {
        let web3 = new Web3()
        web3.setProvider(new Web3.providers.WebsocketProvider(chainRPC_URL))
        return web3;
    }

    /**
     * Returns the correct Amount, caluclated with decimals of Web3.
     * @param decimal
     * @param amount
     * @param maxFloatingNumbers
     * @returns {number}
     */
    getCorrectAmount = (decimal, amount, maxFloatingNumbers) => {
        return Number(parseFloat(this.utils.fromWei(amount, this.getCurrencyUnit(decimal))).toFixed(maxFloatingNumbers))
    }

    getCurrencyUnit = (decimal) => {

        for(let unit in this.utils.unitMap){
            if(this.utils.unitMap[unit].length-1 === decimal)
                return unit;
        }
        return "ether"
    }

    getSettings(){return this.settings}
}

const web3Client = new Web3Client();
export default web3Client;