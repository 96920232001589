import web3Client from "./Web3Client";

class Honeypot{

    web3client = web3Client;

    web3 = this.web3client.state.chainClients.ethereum;
    tokenName = '';
    tokenSymbol = '';
    tokenDecimals = 0;
    maxSell = 0;
    maxTXAmount = 0;
    bnbIN = 1000000000000000000;
    maxTxBNB = null;

    constructor() {

        if(Honeypot._instance){
            Honeypot._instance = this;
        }

        return Honeypot._instance;
    }

    encodeBasicFunction = (web3, funcName) => {
        return web3.eth.abi.encodeFunctionCall({
            name: funcName,
            type: 'function',
            inputs: []
        }, []);
    }

    updateTokenInformation = async(web3, tokenAddress) => {
        web3.eth.call({
            to: tokenAddress,
            value: 0,
            gas: 150000,
            data: this.encodeBasicFunction(web3, 'name'),
        })
            .then(value => {
                this.tokenName = web3.eth.abi.decodeParameter('string', value);
                let x = document.getElementById('token-info');
                if(x != null) {
                    x.innerText = this.tokenName + ' ('+this.tokenSymbol+')';
                }
                // console.log(this.tokenName)
            });

        this.web3.eth.call({
            to: tokenAddress,
            value: 0,
            gas: 150000,
            data: this.encodeBasicFunction(web3, 'symbol'),
        })
            .then(value => {
                this.tokenSymbol = web3.eth.abi.decodeParameter('string', value);
                let x = document.getElementById('token-info');
                if(x != null) {
                    x.innerText = this.tokenName + ' ('+this.tokenSymbol+')';
                }
            });
    }

    getMaxes = async(web3, address) => {
        let sig = web3.eth.abi.encodeFunctionSignature({name: '_maxTxAmount', type: 'function', inputs: []});
        let d = {
            to: address,
            from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
            value: 0,
            gas: 15000000,
            data: sig,
        };
        try {
            let val = await web3.eth.call(d);
            this.maxTXAmount = web3.utils.toBN(val);
            // console.log(val, this.maxTXAmount);
        } catch (e) {
            // console.log('_maxTxAmount: ', e);
            // I will nest as much as I want. screw javascript.
            sig = web3.eth.abi.encodeFunctionSignature({name: 'maxSellTransactionAmount', type: 'function', inputs: []});
            d = {
                to: address,
                from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
                value: 0,
                gas: 15000000,
                data: sig,
            };
            try {
                let val2 = await web3.eth.call(d);
                this.maxSell = web3.utils.toBN(val2);
                // console.log(val2, this.maxSell);
            } catch (e) {

            }
        }
    }

    getDecimals = async(address, web3) => {
        let sig = this.encodeBasicFunction(web3, 'decimals');
        let d = {
            to: address,
            from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
            value: 0,
            gas: 15000000,
            data: sig,
        };
        try {
            let val = await web3.eth.call(d);
            this.tokenDecimals = web3.utils.hexToNumber(val);
        }catch (e) {
            // console.log('decimals', e);
        }
    }

    getBNBIn = async(address, web3) => {
        let amountIn = this.maxTXAmount;
        if(this.maxSell != 0) {
            amountIn = this.maxSell;
        }
        let WETH = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
        let path = [address, WETH];
        let sig = web3.eth.abi.encodeFunctionCall({
            name: 'getAmountsOut',
            type: 'function',
            inputs: [
                {type: 'uint256', name: 'amountIn'},
                {type: 'address[]', name: 'path'},
            ],
            outputs: [
                {type: 'uint256[]', name: 'amounts'},
            ],
        }, [amountIn, path]);

        let d = {
            to: '0x7a250d5630b4cf539739df2c5dacb4c659f2488d',
            from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
            value: 0,
            gas: 15000000,
            data: sig,
        };
        try {
            let val = await web3.eth.call(d);
            let decoded = web3.eth.abi.decodeParameter('uint256[]', val);
            this.bnbIN = web3.utils.toBN(decoded[1]);
            this.maxTxBNB = this.bnbIN;
        } catch (e) {
            console.log(e);
        }
        // console.log(this.bnbIN, amountIn);
    }

    honeypotIs = (address) => {
        // let encodedAddress = web3.eth.abi.encodeParameter('address', address);
        // let contractFuncData = '0xd66383cb';
        // let callData = contractFuncData+encodedAddress.substring(2);
        //
        // let blacklisted = {
        //     '0xa914f69aef900beb60ae57679c5d4bc316a2536a': 'SPAMMING SCAM',
        //     '0x105e62565a31c269439b29371df4588bf169cef5': 'SCAM',
        //     '0xbbd1d56b4ccab9302aecc3d9b18c0c1799fe7525': 'Error: TRANSACTION_FROM_FAILED'
        // };
        // let unableToCheck = {
        //     '0x54810d2e8d3a551c8a87390c4c18bb739c5b2063': 'Coin does not utilise PancakeSwap',
        //     '0xbf4996e7086a96ca07c7595f2f32654019f66f41': 'Coin does not utilise PancakeSwap',
        // };
        //
        // if(blacklisted[address.toLowerCase()] !== undefined) {
        //     let reason = blacklisted[address.toLowerCase()];
        //     let result = '<div style="max-width: 100%;" class="ui compact error message"><div class="header">Yup, honeypot. Run the fuck away.</div><p>Address: ' + address + '</p><p id="token-info">'+this.tokenName + ' ('+this.tokenSymbol+')'+'</p><br>' + reason + '</div>';
        //     console.log(result)
        //     return;
        // }
        // if(unableToCheck[address.toLowerCase()] !== undefined) {
        //     let rreason = unableToCheck[address.toLowerCase()];
        //     let result = '<div style="max-width: 100%;" class="ui compact info message"><div class="header">Unable to check</div><p>The honeypot checker was unable to determine the result for the specified address.<br>'+rreason+'<br>Contact @ishoneypot on telegram for more.</p><p>Address: ' + address + '</p><p id="token-info">'+this.tokenName + ' ('+this.tokenSymbol+')'+'</p><br>' + '' + '</div>';
        //     console.log(result)
        //     return;
        // }

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', 'https://api.staysafu.org/api/scan?tokenAddress='+address+'&key=d85c65b1f580b880dad51a116e543b0f&holdersAnalysis=false&chain=ETH', true);
            // xhr.open('GET', 'https://aywt3wreda.execute-api.eu-west-1.amazonaws.com/default/IsHoneypot?chain=eth&token='+address, true);
            xhr.responseType = 'json';
            xhr.onload = function() {
                let status = xhr.status;

                // console.log(status, xhr.response);

                if (status === 200) {
                    resolve(xhr.response);
                    // console.log(xhr.response)

                    // if(xhr.response.IsHoneypot == false) {
                    //     // showStats(xhr.response);
                    // }else {
                    //     if(xhr.response.Error == "Error: Returned error: execution reverted") {
                    //         // showUnable(xhr.response.Error);
                    //     }
                    //     // showHoneypot(xhr.response);
                    // }
                } else {
                    // showUnable('Internal Error');
                    // console.error(xhr)
                    reject(xhr.response)
                }
            };
            xhr.send();
        })



        // this.updateTokenInformation(web3, address);
    }

    run = async(address) => {
        // let x = this.updateTokenInformation(this.web3, address);
        // return x;
        // await this.getMaxes(this.web3, address);
        // if(this.maxTXAmount != 0 || this.maxSell != 0) {
        //     await this.getDecimals(address, this.web3);
        //     await this.getBNBIn(address, this.web3);
        // }
        await this.honeypotIs(address);
        // await x;
    }



}

const honeypot = new Honeypot();
export default honeypot;