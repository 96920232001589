import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import StrongNodeDashboard from "./Dashboard";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {CookiesProvider} from "react-cookie";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDDellqmCgUspaYJZ4Q6kNnNPrUwgznOEc",
    authDomain: "strongnode-dashboard.firebaseapp.com",
    projectId: "strongnode-dashboard",
    storageBucket: "strongnode-dashboard.appspot.com",
    messagingSenderId: "1032845142085",
    appId: "1:1032845142085:web:8b322b1bd22644a1a29b2c",
    measurementId: "G-353620C9KG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
    // <CookiesProvider>
        <StrongNodeDashboard />
    // </CookiesProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
