import React from 'react';

import {Component} from "react";
import web3Client from "./components/Web3Client";

import styled, {ThemeProvider} from 'styled-components';
import Parent from "./components/ui/Parent";
import {
  ContainerLeft,
  ContainerMain,
  ContainerRight,
  DashboardDiv,
  ContainerMiddle,
  MainContent, ContainerMiddleCenter, Button, Header, HeaderDiv, Footer, WatchAddressDiv, PriceDiv
} from "./dashboard.styles";

import './components/ui/css_global/chart-skills.css';

import Navbar from "./components/ui/navbar/Navbar";
import {SearchDiv, Select} from "./components/ui/navbar/navbar.styles";

import project from "../package.json";

//AdSense
import AdSense from 'react-adsense';
import honeypot from "./components/Honeypot";
// import ThreeDotsWave from "./components/ui/animations/ThreeDotsWave";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";

import BannerImg from './images/banner.png';

const RewardCard = styled.div`
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); 
`;

const MyTheme = {

  myNode: {
    main: 'rgb(250,150,1)'
  },

  primary: {
    main: 'rgb(52,152,219)',
    mainHover: 'rgba(52,152,219)',
    text: 'rgb(77,77,77)'
  },
  secondary: {
    main: 'rgb(108,136,191)',
  },
  border:{
    cool: 'rgb(45,73,255)',
    main: 'rgb(0,0,0)'
  },
  background: {
    light: 'rgb(227,227,227)'
  },
  footer: {
    main: 'rgb(227,227,227)'
  }
}

/**
 * Left SidePanel
 */
class DashboardLeftSidePanel extends Component{

  constructor(props) {
    super(props);

    this.state = {
      checkHoneypotAddress: "",
      checkHoneypotResult: {}
    }

    //Honeypot
    this.honeypot = honeypot;

    //WebClient
    this.web3client = web3Client;

  }

  checkHoneypot = (event) => {
    // console.log(formData)
    event.preventDefault()
    let address = event.currentTarget.elements.honeypotAddress.value;
    // console.log(address)

    //Check if input is address
    let isAddress = this.web3client.state.chainClients.ethereum.utils.isAddress(address);

    if(isAddress){

      this.setState({
        checkHoneypotResult: null
      })

      this.honeypot.honeypotIs(address).then(result => {
        // console.log(result)
        this.setState({
          checkHoneypotResult: result.result,
          checkHoneypotAddress: address
        })
      }).catch(error => {
        console.error(error)
      })
    }else{
      console.error("Check you input. Not a valid address")
    }

  }

  render() {
    return(
        <ContainerLeft>
          {/*<h1>HoneyPot</h1>*/}
          {/*<form action="" onSubmit={this.checkHoneypot}>*/}
          {/*  <input name={"honeypotAddress"} placeholder={"Token"}></input>*/}
          {/*  <button type={"submit"}>Check</button>*/}
          {/*</form>*/}

          {/*{*/}
          {/*  (this.state.checkHoneypotResult === null)*/}
          {/*      ?*/}
          {/*      <ThreeDotsWave className="loadingDots"/>*/}
          {/*      :*/}
          {/*      (Object.keys(this.state.checkHoneypotResult).length !== 0 ) ?*/}
          {/*          <div>*/}
          {/*            <label> Tokenname: {this.state.checkHoneypotResult.tokenName} </label>*/}
          {/*            <label> Honeypot? {this.state.checkHoneypotResult.trade.isHoneypot+""}</label>*/}
          {/*            <label> {this.state.checkHoneypotResult.trade.error}</label>*/}
          {/*          </div>*/}
          {/*          :*/}
          {/*          <></>*/}

          {/*}*/}

        </ContainerLeft>
    );
  }
}

/**
 * Right SidePanel
 */

class DashboardRightSidePanel extends Component{

  render() {
    return <div></div>
  }

}

/**
 * Main Dashboard
 */
class StrongNodeDashboard extends Component {

  // //Cookies
  // static propTypes = {
  //   cookies: instanceOf(Cookies).isRequired
  // };

  constructor(props) {
    super(props);

    this.state = {
      apikey: "Y5H8ZU42XSGZWHBZCU793PF8Y2ZAAX4GBM",

      // addresses: [
      //   // "0x3C968922295Ec7867eeCc965763D58dDf56253F4",
      //   // "0x561500fcC619F21B66e81215D93f68793719890B",
      //   //   "0x05cc4AA7a3A8d4C243Ad3D6721d98505FF1c63B9",
      //   //   "0x38F837FeD0af2281600C433F5e749Ef349Fc669b",
      //   //   "0x59ddd1882Ce9D8242a9043F737Ce95c8C0295Bda"
      // ],


      addresses: [],
      pendingRewards: [],

      fiatCurrencies: [
          {
            name: "eur",
            // icon: "&#xf153;"
            icon: "€"
          },
          {
            name: "usd",
            icon: "$"
            // icon: "&#xf155;"
          },
          {
            name: "eth",
            icon: "Ξ"
            // icon: "&#xf155;"
          },
          {
            name: "btc",
            icon: "₿"
            // icon: "&#xf155;"
          }
      ],
      selectedFiatCurrency: "eur",
      strongPriceInFiat: 0,
      strongerPriceInFiat: 0,

      myAccount: [],

    }

    this.web3client = web3Client;
    this.web3client.setApiKey(this.state.apikey);

    if(window.ethereum !== undefined) {
      window.ethereum.on("accountsChanged", accounts => {
        if (accounts.length > 0)
          return
        else {

          //If Disconnected
          if(window.ethereum.selectedAddress === undefined || window.ethereum.selectedAddress === null) {
            let nodes = this.getNodesFromStorage();

            let state = this.state;
            console.log(state, nodes)
            state.myAccount = [];
            try {
              state.pendingRewards = state.pendingRewards.filter(r => r.nodeAddress.toLowerCase() !== nodes.wallet.toLowerCase());
            }catch (e) {

            }


            if(nodes.wallet) nodes.wallet = undefined;
            localStorage.setItem("nodes", JSON.stringify(nodes))

            if(nodes.otherNodes)
              state.addresses = nodes.otherNodes;

            this.setState(state);
          }


          // console.log(this.props.cookies.get("node"), this.state.addresses)
          // if (this.props.cookies.get("node") !== undefined && this.props.cookies.get("node").hasOwnProperty("wallet")) {
          //   this.setCookie("node", {otherNodes: this.props.cookies.get("node").otherNodes})
          //   let state = this.state;
          //   state.myAccount = [];
          //   state.pendingRewards = [];
          //   state.addresses = this.props.cookies.get("node").otherNodes
          //   this.setState(state)
          //
          //   this.getStrongPrice(this.state.selectedFiatCurrency).then(price => {
          //     this.getStrongPendingRewards(this.state.selectedFiatCurrency, state.addresses);
          //     // this.state.priceInFiat = price;
          //     this.setState({strongPriceInFiat: price.strong, strongerPriceInFiat: price.stronger})
          //   })
          // }
        }
      });
    }

  }

  componentDidMount() {


    try{

      this.fetchStrongPrice()

      if(window.ethereum !== undefined){

        let nodes = this.getNodesFromStorage();

        if(window.ethereum.selectedAddress !== null && window.ethereum.selectedAddress !== undefined){

          console.log("Not null", window.ethereum.selectedAddress)
          this.connectWallet()

          if(nodes){
            console.log("Nodes storage exist!", nodes)
          }

          if(nodes && nodes.otherNodes && nodes.otherNodes.length > 0){
            let state = this.state;
            state.addresses.push(...nodes.otherNodes);
            this.setState(state);
          }
          // this.fetchStrongService()
        }else{
          if(window.ethereum.selectedAddress === null && nodes.wallet){
            this.connectWallet()
          }
        }

        if(nodes && nodes.otherNodes.length > 0){

          let state = this.state;
          state.addresses.push(...nodes.otherNodes);
          this.setState(state);
          this.fetchStrongService()
        }

      }else{
        let nodes = this.getNodesFromStorage();
        nodes.wallet = undefined;
        localStorage.setItem("nodes", JSON.stringify(nodes))

        if(nodes.otherNodes.length > 0){
          this.fetchStrongService()
        }

      }

    }catch (e) {
      console.error(e)
      // alert(e)

    }






    // if(this.props.cookies.get("node") !== undefined && this.props.cookies.get("node").hasOwnProperty("wallet")){
    //   // console.log("Fetch with myNode", this.props.cookies.get("node"))
    //   let state = this.state;
    //   state.myAccount.push(this.props.cookies.get("node").wallet);
    //   this.setState(state);
    // }

    // if(this.props.cookies.get("node") !== undefined && this.props.cookies.get("node").hasOwnProperty("otherNodes")){
    //   let state = this.state;
    //   state.addresses.push(...this.props.cookies.get("node").otherNodes)
    //
    //   if(window.ethereum !== undefined && (window.ethereum.selectedAddress !== undefined || window.ethereum.selectedAddress !== null)){
    //     this.connectWallet()
    //   }
    //   this.setState(state);
    // }
    // this.fetchStrongService()
  }

  /**
   *
   * @param {String} address
   * @param {boolean} myNode
   */
  addNodeToStorage = (address, myNode) => {

    let nodeObject = {
      wallet: undefined,
      otherNodes: []
    }

    let nodes = this.getNodesFromStorage();

    //Check if exist
    if(nodes){
      //Check current Wallet-Address
      if(myNode){
        nodes.wallet = address;

        if(nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() === address.toLowerCase()).length !== 0){
          nodes.otherNodes = nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() !== address.toLowerCase())
        }

        localStorage.setItem("nodes", JSON.stringify(nodes));
      }else{

        if(nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() === address.toLowerCase()).length === 0) {
          nodes.otherNodes.push(address)
        }
        //Setting unique Addresses
        nodes.otherNodes = [...new Set(nodes.otherNodes)];

        if(nodes.wallet){
          nodes.otherNodes = nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() !== nodes.wallet.toLowerCase())
        }

        // if(nodes.wallet && nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() === nodes.wallet.toLowerCase()).length === 0)
        //   nodes.otherNodes.push(address)

        localStorage.setItem("nodes", JSON.stringify(nodes));
      }

    }else{

      let nodeObject = {
        wallet: undefined,
        otherNodes: []
      }

      if(myNode){
        nodeObject.wallet = address;
        localStorage.setItem("nodes", JSON.stringify(nodeObject));
      }else{
        nodeObject.otherNodes.push(address)
        localStorage.setItem("nodes", JSON.stringify(nodeObject));
        }
      }

    // console.log(localStorage)
  }

  getNodesFromStorage = () => {

    let nodes = localStorage.getItem("nodes")
    if(nodes) {
      // console.log(nodes)
      return JSON.parse(nodes);
    }else
      return undefined
  }

  connectWallet = () => {

    this.web3client.enable().then(web3 => {

      this.web3client.getAccounts().then(accounts => {
        // console.log(accounts)
        this.setState({myAccount: accounts})

        this.addNodeToStorage(accounts[0], true);

        // if(this.props.cookies.get("node") === undefined)
        //   this.setCookie("node", {wallet: accounts[0], otherNodes: []})
        // else if(this.props.cookies.get("node").hasOwnProperty("otherNodes")){
        //   if(this.props.cookies.get("node").otherNodes.length > 0){
        //     this.setCookie("node", {wallet: accounts[0], otherNodes: this.props.cookies.get("node").otherNodes})
        //   }else{
        //     this.setCookie("node", {wallet: accounts[0], otherNodes: []})
        //   }
        // }
        this.fetchStrongService(this.getNodesFromStorage().wallet);
        // console.log(this.state)
      })

    })
  }

  // disconnectWallet = () => {
  //   this.web3client.disable();
  // }

  fetchStrongPrice = () => {
    this.getStrongPrice(this.state.selectedFiatCurrency).then(price => {
      this.setState({strongPriceInFiat: price.strong, strongerPriceInFiat: price.stronger})
    })
  }

  fetchStrongService = (address) => {
    let state = this.state;
    if(address){
      state.addresses.push(address);
      state.addresses = [...new Set(state.addresses.map(adr => adr.toLowerCase()))]
      this.setState(state);

      // this.getStrongPrice(this.state.selectedFiatCurrency).then(price => {
        this.getStrongPendingRewards(this.state.selectedFiatCurrency, state.addresses);
        // this.state.priceInFiat = price;
        // this.setState({strongPriceInFiat: price.strong, strongerPriceInFiat: price.stronger})
      // })

    }

    // this.getStrongPrice(this.state.selectedFiatCurrency).then(price => {
    //   this.getStrongPendingRewards(this.state.selectedFiatCurrency, state.addresses);
    //   // this.state.priceInFiat = price;
    //   this.setState({strongPriceInFiat: price.strong, strongerPriceInFiat: price.stronger})
    // })
  }

  handleFiatSelection = (fiat) => {
    // console.log(fiat.target.value)

    let state = this.state;
    state.selectedFiatCurrency = fiat.target.value;

    this.getStrongPrice(this.state.selectedFiatCurrency).then(price => {
      state.strongPriceInFiat = price.strong;
      state.strongerPriceInFiat = price.stronger
      this.setState(state)
    })


    // this.getStrongPendingRewards(state.selectedFiatCurrency);
  }

  getStrongPrice = async(fiat) => {
    let selectedFiatCurrency = (fiat!=null ? fiat : this.state.selectedFiatCurrency);

    // console.log("Get Strong Price", selectedFiatCurrency)

    let request = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=strong&vs_currencies="+selectedFiatCurrency)
    let priceStrong = await request.json();
    request = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=stronger&vs_currencies="+selectedFiatCurrency)
    let priceStronger = await request.json();
    // console.log(price)
    return {strong: priceStrong.strong[selectedFiatCurrency], stronger: priceStronger.stronger[selectedFiatCurrency] };
  }

  getStrongPendingRewards = async(fiat, addresses) => {
    let selectedFiatCurrency = (fiat!=null ? fiat : this.state.selectedFiatCurrency);
    console.log("Fetching Pending Rewards and Price for FIAT["+selectedFiatCurrency+"]")

    // let price = await this.getStrongPrice(selectedFiatCurrency)

    let blockNumber = await this.web3client.getBlockNumber();
    let serviceContract = this.web3client.getStrongServiceContract();

    let serviceContractPolygon = this.web3client.getStrongServiceContract_Polygon();

    let poolServiceContract = this.web3client.getStrongPoolServiceContract();

    let strongTokenContract = this.web3client.getStrongTokenContract();
    let strongerTokenContract = this.web3client.getStrongerTokenContract();

    let stateObject = [];

    for(let address of addresses){
      // console.log("Get NodeInfos of", address)

      let nodesAmount = await serviceContract.methods.entityNodeCount(address).call();
      let strongTokenBalance = await strongTokenContract.methods.balanceOf(address).call()
      let strongTokenBalanceAmount = this.web3client.utils.getCorrectAmount(
          this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
          strongTokenBalance,
          4
      )
      let strongerTokenBalance = await strongerTokenContract.methods.balanceOf(address).call()
      let strongerTokenBalanceAmount = this.web3client.utils.getCorrectAmount(
          this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
          strongerTokenBalance,
          4
      )

      let pendingReward = await serviceContract.methods.getRewardAll(address, blockNumber).call()
      let pendingRewardAmount = this.web3client.utils.getCorrectAmount(
          this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
          pendingReward,
          4
      )

      let stakedAmount = await poolServiceContract.methods.minerBalance(address).call()
      let poolStakedAmount = this.web3client.utils.getCorrectAmount(
          this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
          stakedAmount,
          4
      )

      let stakedPendingReward = await poolServiceContract.methods.getReward(address).call()
      let poolPendingReward = this.web3client.utils.getCorrectAmount(
          this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
          stakedPendingReward,
          4
      )

      // console.log(this.state)

      //POLYGON
      let nodesAmountPolygon = await serviceContractPolygon.methods.entityNodeCount(address).call();

      // console.log(blockNumber)

      let pendingRewardAmountPolygon = 0;

      for(let nodeIdx = 1; nodeIdx <= Number(nodesAmountPolygon); nodeIdx++) {
        let pendingRewardPolygon = await serviceContractPolygon.methods.getNodeReward(address, nodeIdx).call()
        pendingRewardAmountPolygon += this.web3client.utils.getCorrectAmount(
            this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
            pendingRewardPolygon,
            4
        )
      }

      // let pendingRewardPolygon = await serviceContractPolygon.methods.getRewardAll(address, blockNumber).call()
      // let pendingRewardAmountPolygon = this.web3client.utils.getCorrectAmount(
      //     this.web3client.utils.settings.getTokenList("ethereum").STRONG.decimal,
      //     pendingRewardPolygon,
      //     4
      // )

      let obj = {
        nodeAddress: address,
        address: address.substr(0, 8)+"......"+address.substr(36, 42),
        nodesCount: nodesAmount,

        pendingReward: Number((pendingRewardAmount + pendingRewardAmountPolygon).toFixed(4)),
        strongTokenBalance: strongTokenBalanceAmount,
        strongerTokenBalance: strongerTokenBalanceAmount,

        poolStakedAmount: poolStakedAmount,
        poolPendingReward: poolPendingReward,

        nodesCountPolygon: nodesAmountPolygon,
        // pendingRewardPolygon: pendingRewardAmountPolygon,

        // priceInFIAT: price,
        fiatCurrencyName: fiat,
        isMyNode: this.checkIfIsMyNode(address)
      }

      // console.log(obj)

      stateObject.push(obj)
      // console.log("Finished")
      let state = this.state;
      state.pendingRewards = stateObject;

      this.setState(state);
    }

  }

  checkIfIsMyNode = (nodeAddress) => {
    let myAddresses = this.state.myAccount.map(f=>{ return f.toUpperCase(); });
    nodeAddress = nodeAddress.toUpperCase();
    return myAddresses.includes(nodeAddress)
  }

  getFiatIcon = () => {

    let selectedFiat = this.state.fiatCurrencies.filter(value => value.name === this.state.selectedFiatCurrency)
    return selectedFiat[0];
  }

  handleAddressSearch = (newAddress) => {

    if(this.web3client.state.chainClients.ethereum.utils.isAddress(newAddress)){

      let nodes = this.getNodesFromStorage();

      if(nodes && (!nodes.wallet || !nodes.otherNodes)){
        this.addNodeToStorage(newAddress, false)

        if(this.state.addresses.filter(adr => adr.toLowerCase() === newAddress.toLowerCase()).length > 0) {
          alert("Adr already in list")
          // console.log(this.state)
        }else{
          this.fetchStrongService(newAddress)
        }
      }else{
        if(this.state.addresses.filter(adr => adr.toLowerCase() === newAddress.toLowerCase()).length > 0) {
          alert("Adr already in list")
        }else{
          this.addNodeToStorage(newAddress, false)
          this.fetchStrongService(newAddress)
        }
      }

      // // console.log(newAddress, this.state.addresses.filter(address => address.toLowerCase() != newAddress.toLowerCase()))
      // // if(this.state.addresses.filter(address => address.toLowerCase() == newAddress.toLowerCase()).length == 0){
      //
      // let myNodes = this.state.myAccount.map(f=>{ return f.toUpperCase(); });
      // if(myNodes.includes(newAddress.toUpperCase())){
      //   alert("Node already in list")
      //   return;
      // }
      //
      // let nodeCookie = this.props.cookies.get("node");
      //
      // if(nodeCookie !== undefined && nodeCookie.hasOwnProperty("otherNodes")){
      //   //check if already exist
      //   let otherNodes = nodeCookie.otherNodes.map(f=>{ return f.toUpperCase(); });
      //   let checkAddress = newAddress.toUpperCase();
      //   if(!otherNodes.includes(checkAddress)){
      //     nodeCookie.otherNodes.push(newAddress)
      //     this.setCookie("node", nodeCookie);
      //     this.fetchStrongService(newAddress)
      //     alert("Checking new Address")
      //   }else{
      //     alert("Address already in list")
      //   }
      // }else{
      //   this.setCookie("node", {otherNodes: [newAddress]});
      //   this.fetchStrongService(newAddress)
      // }
    }else{
      alert("Not a valid address")
    }



  }

  setCookie = (cookieName, data, options) => {

    const { cookies } = this.props;

    if(options === undefined) options = {};
    options.path = "/";

    cookies.set(cookieName, data, options);

  }

  deleteNode = (address) => {

    let nodes = this.getNodesFromStorage();
    nodes.otherNodes = nodes.otherNodes.filter(nodeAddr => nodeAddr.toLowerCase() != address.toLowerCase());
    localStorage.setItem("nodes", JSON.stringify(nodes))

    let state = this.state;
    state.addresses = nodes.otherNodes;
    state.pendingRewards = state.pendingRewards.filter(node => node.nodeAddress != address);

    // let state = this.state;
    //
    // state.addresses = this.state.addresses.filter(adr => adr != address);
    //
    // state.pendingRewards = state.pendingRewards.filter(node => node.nodeAddress != address);
    //
    // let c = this.props.cookies.get("node");
    // c.otherNodes = this.props.cookies.get("node").otherNodes.filter(adr => adr != address);
    // this.setCookie("node", c);
    //
    // console.log(state)
    //
    this.setState(state);

  }



  render() {

    let myNodes = this.state.pendingRewards.filter(node => node.isMyNode === true)
    // console.log("myNodes", myNodes)
    let otherNodes = this.state.pendingRewards.filter(node => node.isMyNode === false)
    // console.log("otherNodes", otherNodes)

    let gotNodesToWatch = (myNodes.length === 0 && otherNodes.length === 0) ? false : true;
    // let gotNodesToWatch = false;

    console.log(this.state.pendingRewards)

    return(

        <ThemeProvider theme={MyTheme}>
          <DashboardDiv>

            {
              (gotNodesToWatch)
                  ?
                  <Navbar
                      setNewAddress={this.handleAddressSearch}
                      connectWallet={this.connectWallet}
                      disconnectWallet={this.disconnectWallet}
                  >
                    <Selection onChange={this.handleFiatSelection}>

                      {
                        this.state.fiatCurrencies.map((value, key) => {

                          return (
                              <option key={key} value={value.name}>
                                {value.icon+"\t"}
                                {value.name.toUpperCase()}
                              </option>
                          );
                        })
                      }
                    </Selection>
                  </Navbar>
                  :
                  <></>
            }

            <ContainerMain>
              {
                (gotNodesToWatch)
                    ?
                    <React.Fragment>
                      {/*{this.connectWallet()}*/}

                      <DashboardLeftSidePanel name={"leftSidePanel"}/>

                      <ContainerMiddle>

                        {/*<div>*/}

                        {/*{*/}
                        {/*  (myNodes.length >= 1) ? <Header>My STRONBLOCK Node</Header> : <></>*/}
                        {/*}*/}

                        {
                          (myNodes.length >= 1)
                              ?
                              myNodes.map((node, index) => {
                                // let completeAmount = Number((
                                //     node.pendingReward+
                                //     node.tokenBalance+
                                //     node.poolStakedAmount+
                                //     node.poolPendingReward
                                // )*this.state.priceInFiat ).toFixed(3);

                                return (
                                    <RenderNode
                                        key={index}
                                        index={index}
                                        node={node}
                                        strongPriceInFiat={this.state.strongPriceInFiat}
                                        strongerPriceInFiat={this.state.strongerPriceInFiat}
                                        getFiatIcon={this.getFiatIcon}
                                        selectedFiatCurrency={this.state.selectedFiatCurrency}
                                    ></RenderNode>
                                )

                              })
                              :
                              <></>
                        }
                        {/*</div>*/}
                        <hr/>

                        <MainContent>
                          {
                            otherNodes.map((node, index) => {

                              return (
                                  <RenderNode
                                      key={index}
                                      index={index}
                                      node={node}
                                      strongPriceInFiat={this.state.strongPriceInFiat}
                                      strongerPriceInFiat={this.state.strongerPriceInFiat}
                                      getFiatIcon={this.getFiatIcon}
                                      selectedFiatCurrency={this.state.selectedFiatCurrency}

                                      deleteNode={this.deleteNode}
                                  ></RenderNode>
                              )

                            })
                          }
                        </MainContent>

                      </ContainerMiddle>

                      <DashboardRightSidePanel name={"rightSidePanel"} />

                    </React.Fragment>
                    :
                    <React.Fragment>
                      <InitialPage
                          setNewAddress={this.handleAddressSearch}
                          connectWallet={this.connectWallet}
                          prices={ {strong: this.state.strongPriceInFiat, stronger: this.state.strongerPriceInFiat } }
                          getFiatIcon={this.getFiatIcon}
                      ></InitialPage>
                    </React.Fragment>

              }

              {/*<ContainerRight></ContainerRight>*/}

            </ContainerMain>

            <Footer>
              <img src={BannerImg}/>
              <label>&copy; 2022 Cryptago.eu <br/> All rights Reserved</label>
              <p>We are supporting the STRONG-Community by providing dashboards for Nodes</p>

              {/*<Footer.Slider>*/}
              {/*  <i className="arrow up"></i>*/}
              {/*</Footer.Slider>*/}

            </Footer>

          </DashboardDiv>
        </ThemeProvider>

    )
  }

}

const RenderNode = (props) => {

  let strongCompleteAmount = Number((
      // props.node.pendingReward+
      props.node.strongTokenBalance
      // props.node.poolStakedAmount+
      // props.node.poolPendingReward
  )*props.strongPriceInFiat ).toFixed(3);

  let strongerCompleteAmount = Number((
      props.node.pendingReward+
      props.node.strongerTokenBalance+
      props.node.poolStakedAmount+
      props.node.poolPendingReward
  )*props.strongerPriceInFiat ).toFixed(3);

  return(
      <React.Fragment>
        <Parent
            nodeAddress={props.node.nodeAddress}
            isMyNode={props.node.isMyNode}
            key={props.index}
            theme={MyTheme}
            title={props.node.address}

            nodesCount={props.node.nodesCount}
            nodesCountPolygon={props.node.nodesCountPolygon}
            poolStakedAmount={props.node.poolStakedAmount}
            poolPendingReward={props.node.poolPendingReward}
            strongTokenBalance={props.node.strongTokenBalance}
            strongerTokenBalance={props.node.strongerTokenBalance}

            priceToken={props.strongPriceInFiat}
            strongerPriceToken={props.strongerPriceInFiat}

            pendingReward={props.node.pendingReward}
            // pendingRewardPolygon={node.pendingRewardPolygon}

            fiatCurrency={{
              icon: props.getFiatIcon().icon,
              name: props.selectedFiatCurrency.toUpperCase()
            }}
            strongPriceInCurrency={strongCompleteAmount}
            strongerPriceInCurrency={strongerCompleteAmount}
            floatingNumber={3}

            deleteNode={props.deleteNode}
        ></Parent>
      </React.Fragment>
  )

}

const InitialPage = (props) => {

  const [address, setAddress] = React.useState("");

  // const handleSearch = () => {
  //   console.log(address)
  //   props.setNewAddress(address)
  // }

  return(
      <React.Fragment>
        <ContainerLeft name={"containerLeft"}></ContainerLeft>
        <ContainerMiddleCenter theme={MyTheme}>

        <ThemeProvider theme={MyTheme}>
          <HeaderDiv>
            <img src={BannerImg}></img>
            <HeaderDiv.Header>
              Strong-Dashboard
              <HeaderDiv.Subtitle>
                by Cryptago
                <span>v{project.version}</span>
              </HeaderDiv.Subtitle>
            </HeaderDiv.Header>
          </HeaderDiv>

          <PriceDiv>
            <div style={{borderTopRightRadius: "0px", borderRight: "unset"}}>
              <img src={"https://tokens.1inch.io/0x990f341946a3fdb507ae7e52d17851b87168017c.png"}/>
              <header>
                Strong
              </header>
              <label>-</label>
              <label>{props.prices.strong} {props.getFiatIcon().icon}</label>
            </div>
            <div style={{borderTopLeftRadius: "0px"}}>
              <img src={"https://tokens.1inch.io/0x990f341946a3fdb507ae7e52d17851b87168017c.png"}/>
              <header>
                Stronger
              </header>
              <label>-</label>
              <label>{props.prices.stronger} {props.getFiatIcon().icon}</label>
            </div>



            {/*<ul className={"chart-skills"}>*/}
            {/*  <li><span>{props.prices.strong}</span></li>*/}
            {/*  <li><span>{props.prices.stronger}</span></li>*/}
            {/*</ul>*/}


          </PriceDiv>

        </ThemeProvider>


          <div className={"info"}>
            <p>This Dashboard from Cryptago will give you a short overview about your Nodes and calculates rewards, pending rewards and the current value in EUR, USD, ETH or BTC</p>
            <p>Or simply search for other Nodes <span>#NodeArmy</span></p>
          </div>

          {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}

          <div className={"inputs"}>
            <Button theme={MyTheme} id={"explore"} onClick={ () => props.setNewAddress(address)}>Explore</Button>
            <AddressInput address={address} setAddress={setAddress} setNewAddress={props.setNewAddress}></AddressInput>
          </div>

          <div className={"split"} theme={MyTheme}>
            <div/>
            <label>Or</label>
            <div/>
          </div>

          <div className={"connect"}>
            <Button theme={MyTheme} onClick={() => props.connectWallet()}>Connect Wallet</Button>
          </div>



        </ContainerMiddleCenter>
        <ContainerRight name={"containerRight"}></ContainerRight>

      </React.Fragment>
  )

}

const AddressInput = (props) => {

  const [icon, setIcon] = React.useState("");

  const inputField = React.useRef()

  const web3 = web3Client.getWeb3();

  const handleSearch = () => {
    // console.log(props.address)
    props.setNewAddress(props.address)
  }

  const addressInput = (address) => {
    props.setAddress(address)

    if(address.endsWith(".eth", address.length)) {
      web3.eth.ens.getAddress(address).then((ensAddress, error) => {

        if (web3.utils.isAddress(ensAddress)) {
          setIcon("fas fa-check-circle correct")
          props.setAddress(ensAddress)
        } else {
          setIcon("fas fa-times-circle error")
        }
      }).catch((error) => {
        console.log("ERROR", error)
        setIcon("fas fa-times-circle error")
      })
    }else{
      setIcon("")

      if(address.length === 42){
        if(web3.utils.isAddress(address)){
          setIcon("fas fa-check-circle correct")
          props.setAddress(address)
        }else{
          setIcon("fas fa-times-circle error")
        }
      }

    }

  }

  const keyPress = (event) => {
    // console.log(event)
    if(event.code === "Enter"){
      addressInput(event.target.value)
      handleSearch(props.address)
    }
  }

  const clearValue = () => {

    inputField.current.value = ""

    props.setAddress("")
    setIcon("")
  }

  return(
      <WatchAddressDiv>
        <input ref={inputField} placeholder={"Search for Address or ENS-Domain"} onKeyPress={(event) => keyPress(event)} onChange={(event) => {addressInput(event.target.value)}}/>
        <span onClick={() => clearValue(inputField)}>
          <i className={icon}></i>
        </span>
      </WatchAddressDiv>
  )

}

const Selection = (props) => {

  return(
      <Select onChange={props.onChange}>
        {props.children}
      </Select>
  )

}

// export default withCookies(StrongNodeDashboard)

export default StrongNodeDashboard;
