import styled from 'styled-components';

export const DashboardDiv = styled.div`
  
  //height: 120vh;
  
  // background-color: ${props => props.theme.background.light};
  
  overflow: auto;
  
  background-image: linear-gradient(180deg, #ffffff, ${props => props.theme.primary.main});
  
  height: inherit;
  
`;

export const ContainerMain = styled.div`
  
  //overflow-y: scroll;
  
  display: grid;
  grid-template-columns: 0% 100% 0%;
  
  //height: inherit;

  //grid-template-columns: 20% 70% 10%;
  height: calc(100vh - 100px);
  //padding-bottom: 50px;
`;

export const ContainerLeft = styled.div`
    //border: 1px solid black;
  //border-right: 1px solid grey;
  padding-left: 0.5em;
  div{
    display: grid;
    grid-template-rows: auto auto auto;
    color: red;
  }

`;

export const ContainerRight = styled.div`

`;

export const ContainerMiddle = styled.div`

  overflow-y: scroll;
  
  padding: 0px 12px;
  //overflow-y: scroll;
  background-image: linear-gradient(180deg,#ffffff,rgb(52,152,219));


`;

export const ContainerMiddleCenter = styled.div`
  position: relative;
  height: inherit;
  
  padding: 0px 20%;
  
  --elementHight: 36px;

  @media (max-width: 750px) {
    padding: 0px 5%;
  }
  
  @media (max-width: 600px) {
    //padding: 0px 10%;
  }
  
  div[class="info"]{
    text-align: justify;
    //margin: 10px 10px 0px 10px;
    //margin-top: 20px;
    padding: 0px 20px 0px 20px;
    
    border: 2px solid ${props => props.theme.primary.main};
  
    border-radius: 0px 0px 0px 0px;
    //border-bottom-left-radius: 0px;
    
    background-color: ${props => props.theme.background.light};
    
    p{
      color: ${props => props.theme.primary.text};
      @media (max-width: 500px) {
        font-size: 0.9rem;
      }
    }
  }
  
  div[class="inputs"]{

    display: grid;
    grid-template-columns: 30% 70%;
    
    button[id="explore"]{

      height: var(--elementHight);
      padding: 0px;
      line-height: 0px;

      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
      
      @media (max-width: 600px) {
        font-size: 0.75rem;
      }
    }

    input{
      height: var(--elementHight);
      width: 100%;
      border-top: unset;
      border-left: unset;
      
      border-right: 2px solid ${props => props.theme.primary.main};
      border-bottom: 2px solid ${props => props.theme.primary.main};
      
      //padding-left: 12px;
    //  margin-right: 12px;
    //  //padding-right: 20px;
    }
    
  }

  div[class="connect"]{
    
    position: relative;
    display: flex;

    justify-content: center;
    
    //height: 100%;
    
    //top: 20%;
    transform: translateY(40px);
    
    button{

      position: relative;
      top: 0;
      
      //transform: translateY(100px);
      
      width: 300px;
      
      background-color: ${props => props.theme.myNode.main};

      @media (max-width: 600px) {
        font-size: 0.75rem;

        width: 200px;
        height: var(--elementHight);
        padding: 0px;
        line-height: 0px;

        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;

      }
    }
  }
  
  div[class="split"]{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    gap: 8px;
    
    div:before{
      content: '';
      border-left: 1px solid ${props => props.theme.secondary.main};
      border-right: 1px solid ${props => props.theme.secondary.main};
    }
    
    label{
      color: ${props => props.theme.secondary.main};
      font-size: 1.2em;
      //font-weight: 900;
    }
    
  }
  
`;

export const MainContent = styled.div`

  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  
`;

export const Button = styled.button`
  position: relative;
  background-color: ${props => props.theme.primary.main};
  color: #fff;
  
  cursor: pointer;
  
  border: unset;
  //border-radius: 16px;
  
  padding: 0 32px;
  
  font-size: 16px;
  font-weight: 500;
  
  //line-height: 54px;
  height: 50px;
  
  :hover{
    background-color: ${props => props.theme.secondary.main + "!important"} ; 
  }
  
`;

export const WatchAddressDiv = styled.div`

  position: relative;
  
  input{
    
    box-sizing: border-box;
    
    padding-left: 12px;
    padding-right: 30px;
    
    //text-decoration: underline;
    
    @media (max-width: 500px) {
      width: 50%;
      left: 0px;
    }

  }

  span{
    
    position: absolute;
    float: left;
    right: 10px;
    //left: -24px;
    top: 8px;
    
    i.error{
      color: red;
    }
    i.correct{
      color: green;
    }
  }
`;


const Header = styled.label`
  font-size: 2rem;
  font-weight: 600;

  color: ${props => props.theme.primary.main};

  @media (max-width: 750px) {
    font-size: 1.6rem;
  }
  
  //@media (max-width: 650px) {
  //  font-size: 1.4rem;
  //}
  
`;

const Subtitle = styled.h2`

  margin: unset;
  font-size: 0.8em;
  
  @media (max-width: 600px) {
    font-size: 0.6em;
  }
  
  span{
    float: right;
    color: lightgrey;
  }
  
`;

export const HeaderDiv = styled.div`
  
  //padding: 20px 10px 20px 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  
  //val(--imgHeight): 100px;
  //max-height: 100px;
  
  img{
    //width: 80px;
    height: inherit; 
    float: left;
    padding-right: 12px;

    @media (max-width: 750px) {
      height: 70px;
    }

    //@media (max-width: 650px) {
    //  height: 60px;
    //}
    
    @media (max-width: 600px) {
      height: 50px;
    }
    
  }
`;
HeaderDiv.Header = Header;
HeaderDiv.Subtitle = Subtitle;

export const PriceDiv = styled.div`
  
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  div{
    
    padding: 6px;
    
    display: flex;
    align-items: center;
    
    border: 2px solid ${props => props.theme.primary.main};
    border-bottom: unset;
    
    border-radius: 10px 10px 0px 0px;
    
    background-color: ${props => props.theme.background.light};

    @media (max-width: 750px) {
      font-size: 0.8rem;
    }
    
    img{
      height: 35px;

      @media (max-width: 750px) {
        height: 24px;
      }
      
    }
    
    header{
      
      padding: 4px;
      
      font-size: 1.2rem;
      font-weight: 600;
      
      
      color: ${props => props.theme.primary.main};
      //background-color: ${props => props.theme.secondary.main};
      
      
      //border-bottom: 2px solid ${props => props.theme.primary.main};

      @media (max-width: 750px) {
        font-size: 0.8rem;
      }
    }
    
    label{
      font-size: 1.2rem;
      font-weight: 600;
      
      padding: 4px;
      
      text-align: center;
      
      color: ${props => props.theme.primary.main};

      @media (max-width: 750px) {
        font-size: 0.8rem;
      }
      
    }
    
  }
  
`;

const Slider = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -24px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  width: 40px;
  height: 20px;

  background-color: ${props => props.theme.footer.main};

  text-align: center;

  border-radius: 20px 20px 0px 0px;

  @media (max-width: 750px) {
    font-size: 0.8rem;
  }
  
  .arrow{
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

    @media (max-width: 750px) {
      border-width: 0 2px 2px 0;
      padding: 3px;
    }
    
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
`;

export const Footer = styled.footer`
  
  position: absolute;
  
  display: flex;
  align-items: center;
  
  bottom: 0px;
  left: 0px;

  --height: 60px;
  //height: var(--height);
  
  width: 100%;
  
  background-color: ${props => props.theme.footer.main};
  
  img{
    padding: 6px 6px;
    height: calc(var(--height) - 24px);
    float: left;
  }
  
  label{
    color: ${props => props.theme.primary.main};
    
    width: 30%;
    
    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }
  
  p{
    
    width: 50%;
    //text-align: left;
    
    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
    
  }
  
`;
Footer.Slider = Slider;


